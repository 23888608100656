import React from 'react';
import { Link } from "react-router-dom";
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../utils/GlobalStates';
import Grid from '@mui/material/Grid';

import ConstantStyles from '../../../styles/ConstantStyles.json';
import bredas_logo from '../../../assets/img/bredas_logo.png';
import navItems from '../../../utils/NavItems.json';
import footerItems from '../../../utils/FooterItems.json';

import facebookIcon from '../../../assets/img/SocialMedia/facebook_icon.png';
import instagramIcon from '../../../assets/img/SocialMedia/instagram_icon.png';
import twitterIcon from '../../../assets/img/SocialMedia/twitter_icon.png';
const ImageWithCTA = () => {
    const globalStates = useHookState(GlobalStates);
    // useEffect(() => {
    // }, []);

    return (
        <Grid container spacing={2} style={{ paddingTop: "25px", backgroundColor: ConstantStyles['dark-grey'], color: ConstantStyles['grey'] }}>
            <Grid item xs={12} sm={5}>
                <img className='footerLogo' src={bredas_logo} width="200" style={{ float: 'right' }} />

            </Grid>
            <Grid item xs={12} sm={2} className="listFooter">
                <ul style={{ listStyleType: "none" }}>
                    {navItems.map((item) => (
                        <li key={item.title}>
                            <Link style={{ textDecoration: "none", color: "inherit" }} to={item.url}>{item.title}</Link>
                        </li>
                    ))}
                </ul>
            </Grid>
            <Grid item xs={12} sm={5} className="listFooter">
                <ul style={{ listStyleType: "none", padding: "0px" }}>
                    {footerItems.map((item) => (
                        <li key={item.title}>
                            <Link style={{ textDecoration: "none", color: "inherit" }} to={item.url}>{item.title}</Link>
                        </li>
                    ))}
                </ul>
                <div className="socMedIcons">
                    <Link style={{ textDecoration: "none", marginRight: "10px" }} to={"test"}><img width={"32"} src={facebookIcon} /></Link>
                    <Link style={{ textDecoration: "none", marginRight: "10px" }} to={"test"}><img width={"32"} src={instagramIcon} /></Link>
                    <Link style={{ textDecoration: "none", marginRight: "10px" }} to={"test"}><img width={"32"} src={twitterIcon} /></Link>
                </div>
            </Grid>
            <Grid item xs={12} sm={12} style={{ textAlign: "center", marginTop: "20px" }} >
                <small style={{ color: ConstantStyles['grey'] }}>&copy; BREDDAS 2022. ALL RIGHTS RESERVED.</small>
            </Grid>
        </Grid>
    )


}


export default ImageWithCTA;