import React from 'react';
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../utils/GlobalStates';

import {
    Grid,
    Card,
    CardContent
} from '@mui/material';


import ConstantStyles from "../../../styles/ConstantStyles.json";

import testlabs from '../../../assets/img/logo/testlabs.png';
import pagcor from '../../../assets/img/logo/pagcor.png';
import gaminglab from '../../../assets/img/logo/gaminglab.png';

const LogoAccredited = [
    {
        "text": "PAGCOR-CERTIFIED",
        "imgSource": pagcor
    },
    {
        "text": "BMM CERTIFIED",
        "imgSource": testlabs
    },
    {
        "text": "GLI CERTIFIED",
        "imgSource": gaminglab
    }
]

const AccreditedLogo = () => {
    const globalStates = useHookState(GlobalStates);

    // useEffect(() => {
    // }, []);

    return (
        <Grid container justifyContent="center" spacing={0} p={{ xs: 1, sm: 2, md: 2, lg: 8, xl: 8 }} style={{ backgroundColor: ConstantStyles["dark-grey"] }}>

            {LogoAccredited.map((item, index) => (

                <Grid item xs={12} sm={4}>
                    <Card sx={{
                        maxWidth: "100%",
                        backgroundColor: "transparent",
                        boxShadow: "none"
                    }}>

                        <img src={item.imgSource}
                            title={item.text}
                            style={{
                                maxWidth: "100%",
                                width: "50%",
                                height: "auto",
                                margin: "0 auto",
                                display: "block",
                                marginBottom: "10px"
                            }}
                        />

                        <CardContent style={{ padding: "10px", textAlign: "center" }}>
                            <a href="#" style={{ textDecoration: "none", color: ConstantStyles.white, fontWeight: "600", fontSize: "18px" }}>
                                {item.text}
                            </a>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>

    )


}


export default AccreditedLogo;