import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ConstantStyle from '../../../styles/ConstantStyles.json'

import SiteLogo from '../../../assets/img/bredas_logo.png';
import navItems from '../../../utils/NavItems.json';

const drawerWidth = 240;
export default function DrawerAppBar(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                <a href="/">
                    <img src={SiteLogo} width="100" />
                </a>
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.title} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );


    return (
        <Box sx={{ display: 'block' }}>
            <CssBaseline />
            <AppBar component="nav" style={{ background: ConstantStyle["navbar-color"], boxShadow: "unset" }}>
                <Toolbar>
                    <IconButton
                        color="plain"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <IconButton
                        color="plain"
                        aria-label="open drawer"
                        edge="start"
                        sx={{ mr: 2, ml: 0, display: { sm: 'none' }, width: "70%" }}
                    >
                        <a href="/">
                            <img src={SiteLogo} width="100" style={{ display: "block" }} />
                        </a>
                    </IconButton>

                    <Box sx={{ flexGrow: 2, textAlign: "center", display: { xs: 'none', md: 'block' } }}>

                        <div style={{ display: "inline" }}>
                            <a href="/">
                                <img src={SiteLogo} width="120" style={{ marginRight: "30px" }} />
                            </a>
                        </div>
                        <div style={{ textAlign: "center", display: "inline" }}>

                            {navItems.map((item) => (
                                <a href={item.url} style={{ textDecoration: "none" }}>
                                    <Button
                                        key={item}
                                        sx={{ color: '#fff', margin: { xs: '0 10px', sm: '0 10px', md: '0 20px', xl: '0 60px' }, fontSize: "16px", fontWeight: "normal" }}
                                    // onClick={() => window.location.href = item.url}
                                    >
                                        {item.title}
                                    </Button>
                                </a>
                            ))}
                        </div>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, color: ConstantStyle["grey"], backgroundColor: ConstantStyle["navbar-color"] },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box >
    );

}

