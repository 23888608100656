import { useState } from 'react';
import { useState as useHookState } from "@hookstate/core";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
// shared components
import CustomAlert from "./components/shared/CustomAlert";
import CustomCircularProgress from './components/shared/CustomCircularProgress';
// components
import Header from './components/shared/general/Header';
// modules
import Homepage from './components/modules/Home/Homepage';
import Policy from './components/modules/general/Policy/Policy';
import ClassifiationPolicy from './components/modules/general/Policy/section/ClassifiationPolicy';
import DataManagementPolicy from './components/modules/general/Policy/section/DataManagementPolicy';
import PrivacyPolicy from './components/modules/general/Policy/section/PrivacyPolicy';
import RetentionDestructionPolicy from './components/modules/general/Policy/section/RetentionDestructionPolicy';

// styles
import ThemeStyles from "./styles/ThemeStyles";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
// global states
import GlobalStates from './utils/GlobalStates';
// general components

function App(props) {
	const globalStates = useHookState(GlobalStates);

	const [theme, setTheme] = useState(ThemeStyles);


	return (
		<ThemeProvider theme={theme}>
			<Header />
			<BrowserRouter basename={props.baseUrl}>
				<Routes>
					<Route path="/" element={<Homepage />} />
					<Route path="/Policy" element={<Policy />} />
					<Route path="/data-classification" element={<ClassifiationPolicy />} />
					<Route path="/data-management" element={<DataManagementPolicy />} />
					<Route path="/privacy-policy" element={<PrivacyPolicy />} />
					<Route path="/data-retention-destruction" element={<RetentionDestructionPolicy />} />
					<Route path="*" element={<Navigate to="/" />} />
				</Routes>

			</BrowserRouter>

			<CustomAlert />
		</ThemeProvider>
	);
}

export default App;
