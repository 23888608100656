import React from 'react';
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../../utils/GlobalStates';

import {
    Grid,
    Card,
    CardContent
} from '@mui/material';
import Footer from '../../../shared/general/Footer';

const Policy = () => {
    const globalStates = useHookState(GlobalStates);

    // useEffect(() => {
    // }, []);

    return (
        <div >
            <Grid container justifyContent="left" spacing={0} p={{ xs: 5, sm: 5, md: 12, lg: 12, xl: 12 }} sx={{ minHeight: "800px" }} >
                <Card sx={{
                    maxWidth: "100%",
                    width: "100%",
                    padding: "2%",
                    height: "250px",
                    marginTop: { xs: "50px", sm: "50px", md: "30px", lg: "30px", xl: "30px" }
                }}>
                    <h2>Policy</h2>
                    <CardContent style={{ padding: "10px", textAlign: "" }}>
                        <ul>
                            <li>
                                <a href="/data-classification">Breddas Inc. Data Classification Policy</a>
                            </li>
                            <li>
                                <a href="/data-management">Breddas Inc. Data Management Policy</a>
                            </li>
                            <li>
                                <a href="/data-retention-destruction">Breddas Inc. Data Retention and Destruction Policy</a>
                            </li>
                            <li>
                                <a href="/privacy-policy">Breddas Inc. Privacy Policy Manual</a>
                            </li>
                        </ul>
                    </CardContent>
                </Card>
                <br />
            </Grid>

            <Footer />
        </div>
    )


}


export default Policy;