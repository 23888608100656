import React from 'react';
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../utils/GlobalStates';

import {
    Button,
    Grid,
    Card,
    Typography,
    CardHeader,
    CardMedia,
    CardContent,
    CardActions
} from '@mui/material';

import ConstantStyles from "../../../styles/ConstantStyles.json";
import PiratesBabes from '../../../assets/img/Games/Game Previews_trad_Pirate Babes.png';
import GoldenEra from '../../../assets/img/Games/Game Previews_trad_Golden Era.png';
import GoldFarm from '../../../assets/img/Games/Game Previews_trad_Gold Farm.png';
import DragonvsTiger from '../../../assets/img/Games/Game Previews_variant_DvT.png';
import BingoPares from '../../../assets/img/Games/Game Previews_variant_BingoPares.png';
import BallBingo from '../../../assets/img/Games/Game Previews_variant_13BallBingo.png';


const TopGamesAssets = [
    {
        "Title": "Pirates Babes",
        "imgSource": PiratesBabes,
        "description": "Let the pirate babes charm you to a tropical island where you will find gunpowder barrels, cannons, and of course plenty of treasure!"
    },
    {
        "Title": "Golden Era",
        "imgSource": GoldenEra,
        "description": "This bingo game will take you to the peaceful world of ancient China. There is no stopping when you hit the JACKPOT and BONUS WHEELS!"

    },
    {
        "Title": "Gold Farm",
        "imgSource": GoldFarm,
        "description": "Escape the busy city to enjoy a farmyard-themed bingo that boasts its bright and colourful graphics!    "
    },
    {
        "Title": "Dragon vs Tiger",
        "imgSource": DragonvsTiger,
        "description": "An Ancient battle plays out accross the Dragon & Tiger on this Hi - Lo bingo game. Land a win on both sides and double your wins!"
    },
    {
        "Title": "Bingo Pares",
        "imgSource": BingoPares,
        "description": "Bingo Pares is a thrilling, fast-paced two ball bingo game. Bet on STRAIGHT BET, RUMBLE BET, or both to unlock gifts!"
    },
    {
        "Title": "13 Ball Bingo",
        "imgSource": BallBingo,
        "description": "Roulette's raw excitement has alway been the main attraction for casinos — and it's nothing different with this roulette inspired bingo game."
    }
]

const TopGames = () => {
    const globalStates = useHookState(GlobalStates);

    return (
        <div style={{ backgroundColor: ConstantStyles["black"] }}>
            <h2 style={{
                color: ConstantStyles["white"],
                paddingTop: "2%",
                textAlign: "center",
                textTransform: "uppercase"
            }}>Our Top Games</h2>
            <Grid container justifyContent="center" spacing={2} p={{ xs: 2, sm: 2, md: 8 }} >

                {TopGamesAssets.map((item, index) => (

                    <Grid item xs={12} sm={4} >

                        <Card sx={{ maxWidth: "100%" }} style={{
                            backgroundColor: "#222131",
                            minHeight: "300px",
                            color: ConstantStyles.white
                        }}>
                            <CardHeader
                                subheader={<Typography sx={{ color: ConstantStyles.white, fontWeight: "400" }}>{item.Title}</Typography>}
                            />

                            <CardMedia
                                component="img"
                                image={item.imgSource}
                                alt={item.Title}
                                sx={{
                                    objectFit: "unset",
                                    maxHeight: { xs: "500px", sm: "500px", md: "200px", lg: "400px", xl: "250px" }
                                }}
                            />


                            <CardContent key={index}
                                sx={{
                                    padding: "5px 15px",
                                    textAlign: "left",
                                    backgroundColor: "#222131",
                                    color: ConstantStyles.grey,
                                    fontSize: { xs: "14px", md: "14px" },
                                    minHeight: { xs: "unset", sm: "136px", md: "105px", lg: "70px", xl: "75px" }
                                }}>
                                {item.description}
                            </CardContent>
                            <CardActions sx={{ borderTop: "2px solid darkgray" }}>
                                <Button size="large" variant="contained" color="primary" sx={{
                                    color: ConstantStyles.white,
                                    display: "block",
                                    margin: "0 auto",
                                    borderRadius: "50px",
                                    width: "130px",
                                    fontSize: "20px",
                                    padding: "5px"
                                }}>DEMO</Button>
                            </CardActions>
                            {/* </CardActionArea> */}
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div >
    )

}


export default TopGames;