import React from 'react';
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../utils/GlobalStates';
import ImageWithCTA from './ImageWithCTA';
import GamePlatform from './GamePlatform';
import AccreditedLogo from './AccreditedLogo';
import TopGames from './TopGames';
import Footer from '../../shared/general/Footer';
import ContactUs from './ContactUs';

const Homepage = () => {
    const globalStates = useHookState(GlobalStates);

    // useEffect(() => {
    // }, []);

    return (
        <>
            <ImageWithCTA />
            <GamePlatform />
            <AccreditedLogo />
            <TopGames />
            <ContactUs />
            <Footer />
        </>
    )


}


export default Homepage;