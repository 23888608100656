import React from 'react';
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../utils/GlobalStates';
import ConstantStyles from "../../../styles/ConstantStyles.json";
import {
    Button,
    Grid,
    Typography
} from '@mui/material';

import Footer from '../../../assets/img/Footer.png';

const ImageWithCTA = () => {
    const globalStates = useHookState(GlobalStates);

    // useEffect(() => {
    // }, []);

    return (
        <Grid container spacing={2} className="contactUsSection">
            <Grid item xs={12} style={{
                backgroundImage: `url(${Footer})`, backgroundSize: "cover", padding: "90px 0", marginTop: "15px", backgroundPosition: "55% 40% "
            }}>
                <Typography variant="h2" gutterBottom style={{ color: ConstantStyles.white, marginBottom: "0px", textAlign: "center" }}>
                    CONTACT US FOR A FREE DEMO !
                </Typography>
                <Button size="large" variant="contained" color="secondary" sx={{
                    color: ConstantStyles.white,
                    backgroundColor: ConstantStyles.greenCustomize2,
                    display: "block",
                    margin: "0 auto",
                    borderRadius: "50px",
                    width: "200px",
                    fontSize: "20px",
                    padding: "5px"
                }}>CONTACT US</Button>
            </Grid >
        </Grid >
    )


}


export default ImageWithCTA;