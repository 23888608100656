import React from 'react';
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../utils/GlobalStates';
import ConstantStyles from "../../../styles/ConstantStyles.json";
import {
    Button,
    Grid,
    Typography
} from '@mui/material';
import CTA_Img from '../../../assets/img/Landing_BG.png';

const ImageWithCTA = () => {
    const globalStates = useHookState(GlobalStates);
    // useEffect(() => {
    // }, []);

    return (
        <Grid container spacing={2}>
            <Grid item
                xs={12}
                style={{ position: "relative" }}
                sx={{
                    marginTop: { xs: "60px", sm: "0" }
                }}
            >
                <img src={CTA_Img} style={{ width: "100%" }} />
                <div className='cta_part'>
                    <Typography variant="h2" gutterBottom style={{ color: ConstantStyles.white, marginBottom: "0px" }}>
                        Revolutionizing
                    </Typography>
                    <Typography variant="h2" gutterBottom style={{ color: ConstantStyles.primary, marginBottom: "135px", fontWeight: "600" }}>
                        ELECTRONIC BINGO
                    </Typography>
                    <Button size="medium" variant="contained" color="primary"
                        sx={{
                            color: ConstantStyles.white,
                            display: "inline",
                            borderRadius: "50px",
                            width: "40%",
                            marginRight: "10%",
                            fontSize: "16px",
                            padding: "5px"
                        }}>OUR GAMES</Button>

                    <Button size="medium" variant="contained" sx={{
                        color: ConstantStyles.white,
                        background: "transparent",
                        border: "1px solid #fff",
                        display: "inline",
                        borderRadius: "50px",
                        width: "40%",
                        marginRight: "5%",
                        fontSize: "16px",
                        padding: "5px",
                        "&:hover": {
                            background: "transparent"
                        }
                    }}>CONTACT US</Button>
                </div>
            </Grid>
        </Grid>
    )


}


export default ImageWithCTA;