import React from 'react';

import {
    Grid,
    Card,
    CardContent
} from '@mui/material';
import Footer from '../../../../shared/general/Footer';

const ClassifiationPolicy = () => {

    // useEffect(() => {
    // }, []);

    return (
        <div>
            <Grid container justifyContent="left" spacing={0} p={{ xs: 5, sm: 5, md: 12, lg: 12, xl: 12 }} sx={{ minHeight: "" }} >
                <Card sx={{
                    maxWidth: "100%",
                    width: "100%",
                    padding: "2%"
                }}>
                    {/* <h2>Data Classification Policy</h2> */}
                    <CardContent style={{ padding: "10px", textAlign: "" }}>
                        <iframe src="https://breddas-website-files.s3.ap-southeast-1.amazonaws.com/Breddas+Inc.+Data+Classification+Policy.pdf" width="100%" height="750px"></iframe>
                        {/* <embed src="https://breddas-website-files.s3.ap-southeast-1.amazonaws.com/Breddas+Inc.+Data+Classification+Policy.pdf" width="100%" height="750px" /> */}
                    </CardContent>
                </Card>

            </Grid>
            <Footer />
        </div>

    )


}


export default ClassifiationPolicy;