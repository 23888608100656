import React from 'react';
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../utils/GlobalStates';
import ConstantStyles from "../../../styles/ConstantStyles.json";
import DynastyImage from '../../../assets/img/section/PlatformDynasty.png';
import ElectronincBingo from '../../../assets/img/section/ElectronincBingo.png';
import Bet888 from '../../../assets/img/section/888Bet.png';

import {
    Button,
    Grid,
    Card,
    CardMedia,
    CardActions
} from '@mui/material';

const TopGamesAssets = [
    {
        "Title": "DYNASTY GAMING CABINETS",
        "imgSource": DynastyImage,
        "bgColor": ConstantStyles.primary
    },
    {
        "Title": "ELECTRONIC BINGO GAMES",
        "imgSource": ElectronincBingo,
        "bgColor": ConstantStyles.greenCustomize1

    },
    {
        "Title": "ONLINE PLATFORM LUCKY 888 BET",
        "imgSource": Bet888,
        "bgColor": ConstantStyles.greenCustomize2
    }
]

const GamePlatform = () => {
    const globalStates = useHookState(GlobalStates);

    // useEffect(() => {
    // }, []);

    return (
        <Grid container justifyContent="center" spacing={2} p={{ xs: 2, sm: 2, md: 8 }} style={{ backgroundColor: ConstantStyles.black }} >
            {TopGamesAssets.map((item, index) => (
                <Grid item xs={12} sm={4} key={index} >
                    <Card sx={{ maxWidth: "100%" }} style={{
                        backgroundColor: "#222131",
                        minHeight: "200px",
                        color: ConstantStyles.white,
                        maxHeight: "450px"
                    }}>
                        <CardMedia
                            component="img"
                            image={item.imgSource}
                            alt={item.Title}
                            sx={{
                                objectFit: "unset",
                                maxHeight: "100%"
                            }}
                        />
                        <CardActions sx={{ padding: 0 }}>
                            <Button size="large" variant="contained" color="primary"
                                sx={{
                                    color: ConstantStyles.white,
                                    display: "block",
                                    borderRadius: "0",
                                    width: "100%",
                                    fontSize: "20px",
                                    padding: "0px"
                                }}
                                style={{ backgroundColor: item['bgColor'] }}
                            >{item.Title}</Button>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )


}


export default GamePlatform;